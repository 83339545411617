import React from 'react';
import PageHeader from './pageHeader';
import { Breadcrumb } from '../types/Global';

interface Props {
  siteTitle: string
  pageHeader?: string
  breadcrumbs?: Breadcrumb[];
  moreInfo?: string
  moreInfoSize?: string
}

const Header: React.FunctionComponent<Props> = ({ pageHeader, siteTitle, breadcrumbs, moreInfo, moreInfoSize }) => (
  <header className="bg-dstl-purple">
    <div className="flex items-center justify-center md:justify-between p-4 mx-auto">
      <div className="hidden md:block flex-1">
        <a href='https://www.gov.uk/government/publications/dstl-science-and-technology-procurement-frameworks/human-social-science-research-capability-hssrc-framework'>
          <img className="h-8 lg:h-12" alt="Aleph Insights Logo" src="/image/HSSRC-Logo.png" />
        </a>
      </div>
      <div className="flex-none text-aleph-gray text-center md:text-lg lg:text-2xl font-semi-bold">{siteTitle} — OFFICIAL</div>
      <div className="hidden md:block flex-1">
        <div className="grid">
          <div className="justify-self-end">
            <a href="https://www.gov.uk/government/organisations/defence-science-and-technology-laboratory">
              <img className="h-8 lg:h-12" alt="DSTL Logo" src="/image/dstl-logo.svg" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <PageHeader text={pageHeader || ""} breadcrumbs={breadcrumbs} moreInfo={moreInfo} moreInfoSize={moreInfoSize} />
  </header>
);

export default Header;
