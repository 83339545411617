import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

interface MetaType {
  name: string
  content: string
}

interface Props {
  description?: string
  keywords?: string[];
  lang?: string
  meta?: MetaType[]
  title: string
}

const SEO: React.FunctionComponent<Props> = ({ description, lang, meta, keywords, title }) => {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

  const pageKeywords = keywords || [];
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          pageKeywords.length > 0
            ? {
              name: `keywords`,
              content: pageKeywords.join(`, `),
            }
            : []
        )
        .concat(meta || [])}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    />
  );
}

export default SEO;
