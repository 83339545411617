/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Header from "./header";
import { Breadcrumb } from '../types/Global';

interface Props {
  children?: React.ReactNode
  title?: string
  pageHeader?: string
  breadcrumbs?: Breadcrumb[]
  moreInfo?: string
}

const Layout: React.FunctionComponent<Props> = ({ title, children, pageHeader, breadcrumbs, moreInfo }) => {
  const { site, allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
        allMarkdownRemark(
          filter: {frontmatter: { type: { eq: "MoreInfo" } } }
        ) {
          edges {
            node {
              html
              frontmatter {
                page
                type
                size
              }    
            }
          }
        }    
      }
    `
  )
  const moreInfoNode = allMarkdownRemark.edges.find(
    (edge:any) => (edge.node.frontmatter.type === "MoreInfo" && edge.node.frontmatter.page === moreInfo)    
  )
  return (
    <div className="flex flex-col h-screen font-sans text-gray-900">
      <Header
        siteTitle={title || site.siteMetadata.title}
        pageHeader={pageHeader}
        breadcrumbs={breadcrumbs}
        moreInfo={(moreInfoNode && moreInfoNode.node) ? moreInfoNode.node.html : undefined}
        moreInfoSize={(moreInfoNode && moreInfoNode.node) ? moreInfoNode.node.frontmatter.size : undefined}
      />

      <main className="flex-1 overflow-y-auto w-full">
        {children}
      </main>

      <footer className="bg-dstl-purple"/>
    </div>
  );
}

export default Layout;
