/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Link } from 'gatsby'
import React, { Fragment, useState } from 'react'
import { HomeIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { Dialog, Transition } from '@headlessui/react'
import MoreInfoBox from './MoreInfoBox';

interface Breadcrumb {
  label: string
  link: string
}

interface Props {
  text: string
  breadcrumbs?: Breadcrumb[]
  moreInfo?: string
  moreInfoSize?: string
}

const PageHeader: React.FunctionComponent<Props> = ({ text, breadcrumbs, moreInfo, moreInfoSize }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="text-white">
      <div className="flex bg-dstl-crimson border-b border-white items-center justify-center md:justify-between mx-auto">
        <nav className="h-8 flex" aria-label="Breadcrumb">
          <ol className="w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
            <li className="flex">
              <div className="flex items-center">
                <Link to="/" className="text-gray-100 hover:text-white">
                  <HomeIcon className="flex-shrink-0 h-7 w-7 inline mr-2" aria-hidden="true" />
                  <span className="hidden md:inline font-bold align-middle">Future Workforce</span>
                </Link>
              </div>
            </li>
            {breadcrumbs && breadcrumbs.map((page) => (
              <li key={page.label} className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-white"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <Link
                    to={page.link}
                    className="truncate ml-4 font-bold text-gray-100 hover:text-white"
                  >
                    {page.label}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      {text && text !== "" &&
        <div className="bg-white text-black text-center p-4 text-3xl font-bold">
          <h1>{text}</h1>
        </div>
      }
    </div>
  )
};

export default PageHeader;
